import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
    selectActiveChargieId,
    selectBiometricsExists,
    selectBiometricsType,
    selectErrorType,
    selectFinishedTransaction,
    selectInactiveChargieId,
    selectRefreshToken,
    selectReviewPrompt,
    selectScrollPosition,
    setCurrentPage,
    setErrorType,
    setFinishedTransaction,
    setInactiveChargieId,
    setReviewPrompt,
    setShowNavigator,
} from '../../appSlice';
import { ReactComponent as LogoIcon } from '../../assets/logo.svg';
import BiometricsModal from '../../components/BiometricsModal';
import ErrorModal from '../../components/ErrorModal';
import ReviewPrompt from '../../components/ReviewModal';
import withTokenWatcher from '../../components/HOC/withTokenWatcher';
import ChargingHistory from '../../components/Home/ChargingHistory';
import Mapbox from '../../components/Home/Map';
import RecentStations from '../../components/Home/RecentStations';
import WelcomeHeader from '../../components/Home/WelcomeHeader';
import RecentDetailsModal from '../../components/RecentDetailsModal';
import SessionBox from '../../components/Session';
import SessionCompleteModal from '../../components/SessionCompleteModal';
import {
    dismissLowSignalModal,
    getReviewPrompt,
    hideScanner,
    setSignupSubscription,
    setStatusBarLight,
} from '../../utils/webview/messages';
import { loader } from 'graphql.macro';
import { useLazyQuery, useMutation } from '@apollo/client';

const completeSessionMutation = loader('./clearCompletedSession.gql');
const checkReviewPromptQuery = loader('./checkReviewPrompt.gql');

function HomePage() {
    const dispatch = useAppDispatch();
    const { state } = useLocation();
    const refreshToken = useAppSelector(selectRefreshToken);
    const biometricsExists = useAppSelector(selectBiometricsExists);
    const biometricsType = useAppSelector(selectBiometricsType);
    const inactiveChargieId = useAppSelector(selectInactiveChargieId);
    const errorType = useAppSelector(selectErrorType);
    const finishedTransaction = useAppSelector(selectFinishedTransaction);
    const [selectedQr, setSelectedQr] = useState<string | null>(null);
    const [errorVisible, setErrorVisible] = useState(false);
    const scrollPosition = useAppSelector(selectScrollPosition);
    const reviewPrompt = useAppSelector(selectReviewPrompt);
    const navigate = useNavigate();

    const [biometricsModalOpen, setBiometricsModalOpen] = useState(
        !!state?.promptBiometrics && !biometricsExists && !!biometricsType
    );
    const [completeSession, { data: completeSessionData }] = useMutation(
        completeSessionMutation
    );

    const [checkReviewPrompt, { data: reviewPromptData }] = useLazyQuery(
        checkReviewPromptQuery
    );

    const closeSessionComplete = () => {
        dispatch(setFinishedTransaction(undefined));
        dispatch(setInactiveChargieId(null));
    };

    const closeError = async () => {
        try {
            setErrorVisible(false);
            dispatch(setErrorType(null));
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        if (reviewPromptData && reviewPromptData.checkAppReviewPrompt) {
            getReviewPrompt();
        }
    }, [reviewPromptData]);

    const closeReview = () => {
        dispatch(setReviewPrompt(false));
    };

    const closeReviewAndRedirect = () => {
        dispatch(setReviewPrompt(false));
        navigate('/contact', { state: { slide: true } });
    };

    const handleRecentDetailsModalToggle = () => {
        setSelectedQr(null);
    };

    useEffect(() => {
        dispatch(setShowNavigator(true));
        dispatch(setCurrentPage('home'));
        setStatusBarLight(true);
        hideScanner();
        setSignupSubscription(false);
        dismissLowSignalModal();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const attemptComplete = async () => {
            try {
                await completeSession();
                if (finishedTransaction) await checkReviewPrompt();
            } catch (error) {
                console.error('Error', error);
            }
        };

        attemptComplete();
    }, [finishedTransaction]);

    useEffect(() => {
        return () => {
            setTimeout(() => {
                if (
                    document.documentElement.getElementsByClassName('content')
                        .length > 0
                )
                    document.documentElement
                        .getElementsByClassName('content')[0]
                        .scrollTo(0, scrollPosition);
            }, 100);
        };
    }, []);

    useEffect(() => {
        if (errorType) {
            setErrorVisible(true);
        }
    }, [errorType]);

    return (
        <div className="container" id="home_page">
            <div className="inset__header" />
            <div className="content" id="home_page_content">
                <div className="home__header">
                    <LogoIcon style={{ marginTop: 15 }} id="home_page_logo" />
                </div>
                <WelcomeHeader loggedIn={!!refreshToken} />
                <SessionBox />
                <div className="home__title">Stations around you</div>
                <div className="home__map">{<Mapbox />}</div>
                {!refreshToken && (
                    <div className="home__title">Getting started</div>
                )}
                {refreshToken && (
                    <div className="home__title">Recent stations</div>
                )}
                <RecentStations onPress={setSelectedQr} />
                <div className="home__title">Charging history</div>
                <ChargingHistory home />
            </div>

            {finishedTransaction && inactiveChargieId && (
                <SessionCompleteModal
                    onClose={closeSessionComplete}
                    chargieId={inactiveChargieId}
                    kwh={finishedTransaction.kwh}
                    cost={finishedTransaction.cost}
                    duration={finishedTransaction.duration}
                />
            )}

            <ErrorModal
                isOpen={errorVisible}
                onClose={closeError}
                errorType={errorType}
            />
            {reviewPrompt && (
                <ReviewPrompt
                    onClose={closeReview}
                    onCloseRedirect={closeReviewAndRedirect}
                />
            )}

            <BiometricsModal
                isOpen={biometricsModalOpen}
                onClose={() => setBiometricsModalOpen(false)}
            />
            {selectedQr && (
                <RecentDetailsModal
                    onClose={handleRecentDetailsModalToggle}
                    qrCode={selectedQr}
                />
            )}
        </div>
    );
}

export default withTokenWatcher(HomePage);
