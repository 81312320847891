import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as Arrow } from '../../assets/icons/accordionArrow.svg';
import { ReactComponent as BackIcon } from '../../assets/icons/backIcon.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/CloseIcon.svg';
import { motion } from 'framer-motion';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
    selectDeviceToken,
    selectEmail,
    setShowNavigator,
} from '../../appSlice';
import { useForm } from 'react-hook-form';
import { colors } from '../../global/variables';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { store } from '../../app/store';

interface ContactFormValues {
    email: string;
    stationId: string;
    subject: string;
    message: string;
}
declare global {
    interface Window {
        $zoho: any;
    }
}
const contactSupportMutation = loader('./contactSupport.gql');

const ContactPage: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const [category, setCategory] = useState('Select category');
    const email = useAppSelector(selectEmail);
    const [resultMessage, setResultMessage] = useState<string | null>(null);
    const [contactSupport, { data, error, loading }] = useMutation(
        contactSupportMutation
    );
    const [inputValue, setInputValue] = useState('');
    const [emailValue, setEmailValue] = useState(email);
    const [categoryValue, setCategoryValue] = useState('');
    const [categoryError, setCategoryError] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const location = useLocation();

    const inputLimit = 5;

    const onSubmit = async (data: ContactFormValues) => {
        if (categoryValue === '') {
            setCategoryError(true);
            return;
        } else {
            setCategoryError(false);
        }
        try {
            const result = await contactSupport({
                variables: {
                    input: {
                        email: data.email,
                        stationId: data.stationId,
                        message: data.message,
                        subject: categoryValue,
                    },
                },
            });
            setResultMessage(
                'Thank you for your submission. An agent will follow up with you as soon as possible.'
            );
        } catch {
            setResultMessage(
                'Sorry, something went wrong.  Please try again at a later time.'
            );
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const input = e.target.value;
        if (/^[0-9\b]+$/.test(input) || input === '') {
            if (e.target.value.length <= inputLimit)
                setInputValue(e.target.value);
        }
    };

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmailValue(e.target.value);
    };

    const {
        register,
        handleSubmit,
        formState: { errors: formErrors },
    } = useForm<ContactFormValues>({
        defaultValues: { email: email || '' },
    });

    const onClose = () => {
        setIsOpen(false);
    };

    const handleOptionChange = (value: string) => {
        setCategory(value);
        setCategoryValue(value);
        if (inputRef && inputRef.current) {
            inputRef.current.value = value;
        }
    };

    const options = [
        'Station damaged',
        'Station unavailable',
        'Charge not starting',
        'Billing/Account issues',
        'RFID is not scanning',
        'Other',
    ];

    const selectCategory = () => {
        setIsOpen(true);
    };

    useEffect(() => {
        dispatch(setShowNavigator(false));
        window.$zoho = {};
        window.$zoho.salesiq = {
            widgetcode:
                'cfe5a9133dd85207259d99e85044c2960319418500c5bd76803cbd17f80c23c1',
            values: {},
            ready: function () {
                window.$zoho.salesiq.visitor.email(email || '');
            },
        };
        const d = document;
        let s;
        s = d.createElement('script');
        s.type = 'text/javascript';
        s.id = 'zsiqscript';
        s.defer = true;
        s.src = 'https://salesiq.zoho.com/widget';
        let t;
        t = d.getElementsByTagName('script')[0];
        if (t.parentNode) t.parentNode.insertBefore(s, t);
        if (window.$zoho.salesiq.floatbutton)
            window.$zoho.salesiq.floatbutton.visible('show');
        return () => {
            if (window.$zoho.salesiq && window.$zoho.salesiq.visitor) {
                window.$zoho.salesiq.visitor.email('');
                window.$zoho.salesiq.reset();
            }
        };
    }, []);

    const goBack = () => {
        if (window.$zoho.salesiq.floatbutton) {
            window.$zoho.salesiq.floatbutton.visible('hide');
            window.$zoho.salesiq.floatwindow.visible('hide');
        }
        if (window.$zoho?.salesiq && window.$zoho?.salesiq?.visitor) {
            window.$zoho.salesiq.visitor.email('');
            window.$zoho.salesiq.reset();
        }
        navigate(-1);
    };

    return (
        <motion.div
            id="contact_page"
            className="container"
            initial={
                location.state && location.state.slide === true
                    ? {
                          marginLeft: '100%',
                          width: '100%',
                          transformOrigin: 'left',
                      }
                    : {}
            }
            animate={{ marginLeft: 0 }}
        >
            <div className="header" id="contact_page_header">
                <div className="header__content">
                    <div
                        className="header__back"
                        onClick={goBack}
                        id="contact_page_back"
                    >
                        <BackIcon id="contact_page_back_icon"></BackIcon>
                    </div>
                    CONTACT US
                </div>
            </div>
            <div className="page">
                {!resultMessage && (
                    <form className="form" onSubmit={handleSubmit(onSubmit)}>
                        <input
                            className="input contact"
                            type="email"
                            style={{ marginTop: 20 }}
                            value={emailValue}
                            id="email"
                            placeholder="Email"
                            {...register('email', {
                                required: 'Email is required',
                                onChange: handleEmailChange,
                            })}
                        />
                        {formErrors.email && (
                            <span className="form__error">
                                Email is required
                            </span>
                        )}
                        <input
                            className="input contact"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            type="number"
                            value={inputValue}
                            id="stationId"
                            placeholder="Station ID (ex. 12345)"
                            {...register('stationId', {
                                onChange: handleInputChange,
                            })}
                        />
                        <input
                            className="input contact"
                            style={{ display: 'none' }}
                            type="text"
                            id="subject"
                            placeholder="Select category"
                            {...register('subject')}
                            ref={inputRef}
                        />
                        <button
                            className="dropdown__replacement"
                            type="button"
                            onClick={selectCategory}
                        >
                            {category}
                            <Arrow />
                        </button>
                        {categoryError && (
                            <span className="form__error">
                                Please select a reason
                            </span>
                        )}
                        <textarea
                            className="textarea"
                            placeholder="How can we help? Please provide a brief description of your issue."
                            {...register('message', {
                                required: 'Please provide a short summary',
                            })}
                        />
                        {formErrors.message && (
                            <span className="form__error">
                                Please enter a brief description
                            </span>
                        )}
                        <button
                            type="submit"
                            className="button"
                            disabled={loading}
                            id="contact_page_submit_button"
                        >
                            Submit
                        </button>
                    </form>
                )}
                {resultMessage && (
                    <div className="result-message">{resultMessage}</div>
                )}

                <div key="zsiqwidget" id="zsiqwidget"></div>
            </div>
            {isOpen && (
                <div className="modal" id="contact_page_modal">
                    <div
                        className="modal__background"
                        onClick={onClose}
                        id="contact_page_modal_background"
                    ></div>
                    <motion.div
                        initial={{ top: '100%' }}
                        animate={{ top: 0 }}
                        className="modal__content"
                        id="contact_page_modal_content"
                    >
                        <div className="modal__close-container">
                            <div
                                className="modal__close"
                                onClick={onClose}
                                id="contact_page_close"
                            >
                                <CloseIcon
                                    fill={colors.inactiveWhite}
                                    id="contact_page_close_button"
                                />
                            </div>
                        </div>
                        <ul className="modal__radio">
                            {options.map((option, index) => (
                                <li className="modal__radio-li">
                                    <input
                                        type="radio"
                                        className="checkbox-input"
                                        id={`option-${index}`}
                                        checked={category === option}
                                        onChange={() =>
                                            handleOptionChange(option)
                                        }
                                    />
                                    <span
                                        className="checkbox-custom"
                                        id={
                                            'contact_page_modal_checkbox_' +
                                            index
                                        }
                                    ></span>
                                    <label
                                        id={'contact_page_modal_label_' + index}
                                        className="modal__radio-label"
                                        htmlFor={`option-${index}`}
                                    >
                                        {option}
                                    </label>
                                </li>
                            ))}
                        </ul>
                    </motion.div>
                    <div className="modal__content--inset" />
                </div>
            )}
        </motion.div>
    );
};

export default ContactPage;
