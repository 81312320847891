import { useLazyQuery, useMutation } from '@apollo/client';
import axios from 'axios';
import { loader } from 'graphql.macro';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
    selectModalBackground,
    selectRefreshToken,
    setCurrentPage,
    setDesiredChargieId,
    setModalBackground,
    setRequestedChargieId,
    setShowNavigator,
} from '../../appSlice';
import { ScannerType, StartMethod } from '../../utils/enums';
import {
    hideScanner,
    maximizeScanner,
    minimizeScanner,
    notifyLowSignalQueue,
    notifyLowSignalScan,
    setStatusBarLight,
    showScanner,
} from '../../utils/webview/messages';

const ScanPage: React.FC = () => {
    const dispatch = useAppDispatch();
    const params = useParams();
    const [inputValue, setInputValue] = useState(params.station);
    const [buttonDisable, setButtonDisable] = useState(true);
    const inputRef = useRef<HTMLInputElement>(null);
    const modalBackground = useAppSelector(selectModalBackground);
    const [isLowSignal, setIsLowSignal] = useState(false);
    const [notifyLowSignal, setNotifyLowSignal] = useState(false);
    const refreshToken = useAppSelector(selectRefreshToken);
    const inputLimit = 5;
    const [remoteStartLoading, setRemoteStartLoading] = useState(false);
    // When the input box is focused, we want to minize the scanner
    const handleFocus = () => {
        minimizeScanner();
        dispatch(setShowNavigator(false));
    };

    //When input is unfocused, we want to show the full scanner and update UI
    const handleBlur = () => {
        maximizeScanner();
        dispatch(setShowNavigator(true));
        if (inputValue && !buttonDisable) {
            setRemoteStartLoading(true);
            setButtonDisable(true);
            dispatch(
                setRequestedChargieId({
                    chargieId: inputValue,
                    startType: StartMethod.MANUAL,
                })
            );
        }
    };

    //Checks for low signal
    useEffect(() => {
        showScanner(ScannerType.STATION);
        setStatusBarLight(true);
        dispatch(setCurrentPage('scan'));
        dispatch(setDesiredChargieId(null));
        if (!refreshToken) return;

        const fetchData = async () => {
            try {
                const response = await axios.get(
                    'https://auth.chargieengine.com/health'
                );
                if (response) {
                    setIsLowSignal(false);
                    setNotifyLowSignal(false);
                }
            } catch (error: any) {
                if (!notifyLowSignal || isLowSignal) {
                    setIsLowSignal(true);
                    notifyLowSignalScan(true);
                }
            }
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const input = e.target.value;
        if (/^[0-9\b]+$/.test(input) || input === '') {
            if (e.target.value.length <= inputLimit)
                setInputValue(e.target.value);
        }
        if (e.target.value.length >= inputLimit) setButtonDisable(false);
        else setButtonDisable(true);
    };

    const handleInputSubmit = async (wasManual: boolean = true) => {
        if (inputValue) {
            setButtonDisable(true);
            if (wasManual) {
                dispatch(
                    setRequestedChargieId({
                        chargieId: inputValue,
                        startType: StartMethod.MANUAL,
                    })
                );
            } else {
                dispatch(
                    setRequestedChargieId({
                        chargieId: inputValue,
                        startType: StartMethod.NATIVE_SCAN,
                    })
                );
            }
        }
    };

    const handleDismiss = () => {
        notifyLowSignalScan(false);
        notifyLowSignalQueue(false);
        dispatch(setModalBackground(false));
    };

    useEffect(() => {
        // Auto submit the qrCode if coming from access.chargie
        if (params.station) {
            setTimeout(() => handleInputSubmit(false), 500);
        }
        dispatch(setShowNavigator(true));
        return () => hideScanner();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.station]);

    return (
        <div className="container noNav" id="scan_page">
            <div className="header" id="scan_page_header">
                <div className="header__content" id="scan_page_header_content">
                    SCAN
                </div>
            </div>
            {modalBackground && (
                <div className="modal">
                    <div
                        id="scan_page_modal_background"
                        className="modal__background"
                        onClick={handleDismiss}
                    />
                </div>
            )}
            <div className="content">
                <div className="scan__manual-container">
                    <p className="scan__manual-text">Or enter Station ID</p>
                    <div className="scan__manual-content">
                        <input
                            ref={inputRef}
                            className="scan__input"
                            value={inputValue}
                            onChange={handleInputChange}
                            placeholder="5-Digit ID"
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            inputMode="numeric"
                            pattern="[0-9]*"
                            type="tel"
                        />
                        <button
                            className="scan__button"
                            disabled={buttonDisable}
                            onClick={() => handleInputSubmit(true)}
                            id="scan_page_scan_button"
                        >
                            {remoteStartLoading ? 'Starting' : 'Start'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ScanPage;
