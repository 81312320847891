import PrimaryButton from '../../components/PrimaryButton';
import { colors } from '../../global/variables';
import { open3dsModal, triggerRefresh } from '../../utils/webview/messages';
import { ReactComponent as CloseIcon } from '../../assets/icons/CloseIcon.svg';
import { useAppSelector } from '../../app/hooks';
import { selectAccessToken } from '../../appSlice';
import { useEffect } from 'react';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/client';
import { useParams, useSearchParams } from 'react-router-dom';

const retryRemoteStartQuery = loader('../../gql/remoteStart.graphql');
const retryGuestRemoteStartQuery = loader(
    '../../components/PricingDetails/guestRemoteStart.graphql'
);

const StartRedirectPage = () => {
    const accessToken = useAppSelector(selectAccessToken);
    const returnToApp = () => {
        open3dsModal(undefined);
    };
    const params = useParams();
    let [searchParams] = useSearchParams();

    const [
        retryRemoteStart,
        { data: retryRemoteStartData, loading: retryRemoteStartLoading },
    ] = useMutation(retryRemoteStartQuery);

    const [
        retryGuestRemoteStart,
        {
            data: retryGuestRemoteStartData,
            loading: retryGuestRemoteStartLoading,
        },
    ] = useMutation(retryGuestRemoteStartQuery);

    useEffect(() => {
        const performActions = async () => {
            const paymentIntentId =
                params.payment_intent || searchParams.get('payment_intent');
            const qrCode = params.qr_code || searchParams.get('qr_code');

            const contact = params.contact || searchParams.get('contact');
            const paymentMethod =
                params.payment_method || searchParams.get('payment_method');
            const method = params.method || searchParams.get('method');
            if (accessToken) {
                try {
                    await retryRemoteStart({
                        variables: {
                            qrCode: qrCode,
                            retry: paymentIntentId,
                        },
                    });
                    triggerRefresh();
                } catch (e) {
                    console.log(e);
                }
            } else if (!accessToken && contact && paymentMethod) {
                try {
                    await retryGuestRemoteStart({
                        variables: {
                            input: {
                                phone: contact.trim(),
                                qrCode: qrCode,
                                paymentMethod: paymentMethod,
                                method: method,
                                retry: paymentIntentId,
                            },
                        },
                    });
                } catch (e) {
                    console.log(e);
                }
            }
        };
        performActions();
    }, [accessToken]);

    return (
        <div className="container" id="payment_redirect_page">
            <div
                style={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexDirection: 'column',
                    paddingRight: 40,
                    paddingTop: 60,
                    paddingLeft: 40,
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        width: '100%',
                    }}
                    id="payment_redirect_close_container"
                >
                    <div onClick={returnToApp} id="error_modal_close">
                        <CloseIcon
                            fill={colors.inactiveWhite}
                            id="error_modal_close_icon"
                        />
                    </div>
                </div>
                <div style={{ marginBottom: 20, textAlign: 'center' }}>
                    Verification has been sent to your bank for approval.
                </div>
                <PrimaryButton
                    disabled={retryRemoteStartLoading}
                    title={'Continue'}
                    onPress={() => returnToApp()}
                />
            </div>
        </div>
    );
};

export default StartRedirectPage;
