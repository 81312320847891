import { store } from '../../app/store';
import {
    setAccessToken,
    setRefreshToken,
    setActiveChargieId,
    setDesiredChargieId,
    setQueuedChargieId,
    setHasPaymentMethod,
    setIsSubscribed,
    setFirstName,
    setLastName,
    setEmail,
    setRequestedChargieId,
} from '../../appSlice';
import { setPaymentMethod } from '../../guestSlice';
import { Guest, ScannerType, WebViewMessageType } from '../enums';

declare global {
    interface Window {
        ReactNativeWebView: any;
        webkit: any;
    }
}

export interface ChargieMessage {
    action: WebViewMessageType;
    payload: any;
}

function postMessage(payload: ChargieMessage) {
    if (window.ReactNativeWebView?.postMessage) {
        window.ReactNativeWebView?.postMessage(JSON.stringify(payload));
    } else if (window.webkit?.messageHandlers.ReactNativeWebView.postMessage) {
        window.webkit?.messageHandlers.ReactNativeWebView.postMessage(
            JSON.stringify(payload)
        );
    }
}

export const init = () => {
    postMessage({ action: WebViewMessageType.INIT, payload: undefined });
};

export const showAdmin = () => {
    postMessage({ action: WebViewMessageType.SHOW_ADMIN, payload: undefined });
};

export const setEnvironment = (payload: string) => {
    postMessage({ action: WebViewMessageType.SET_ENVIRONMENT, payload });
};

export const saveData = (key: string, value: any) => {
    postMessage({
        action: WebViewMessageType.SAVE_DATA,
        payload: {
            key,
            value: typeof value === 'string' ? value : JSON.stringify(value),
        },
    });
};

export const saveNetworkError = (
    operationName: string,
    stationId: string,
    time: string
) => {
    postMessage({
        action: WebViewMessageType.SAVE_BAD_NETWORK_REQUESTS,
        payload: {
            op: operationName,
            id: stationId,
            time: time,
        },
    });
};

export const deleteData = (key: string) => {
    postMessage({ action: WebViewMessageType.DELETE_DATA, payload: { key } });
};

export const logout = () => {
    store.dispatch(setAccessToken(null));
    store.dispatch(setRefreshToken(null));
    store.dispatch(setActiveChargieId(null));
    store.dispatch(setRequestedChargieId(undefined));
    store.dispatch(setDesiredChargieId(null));
    store.dispatch(setQueuedChargieId(null));
    store.dispatch(setHasPaymentMethod(false));
    store.dispatch(setIsSubscribed(false));

    store.dispatch(setFirstName(''));
    store.dispatch(setLastName(''));
    store.dispatch(setEmail(''));
    store.dispatch(setPaymentMethod(null));
    postMessage({ action: WebViewMessageType.LOGOUT, payload: undefined });
};

export const hideScanner = () => {
    postMessage({
        action: WebViewMessageType.HIDE_SCANNER,
        payload: undefined,
    });
};

export const showScanner = (type = ScannerType.STATION) => {
    postMessage({
        action: WebViewMessageType.SHOW_SCANNER,
        payload: {
            type: type,
        },
    });
    return store.dispatch(setActiveChargieId(null));
};

export const minimizeScanner = () => {
    postMessage({
        action: WebViewMessageType.MINIMIZE_SCANNER,
        payload: undefined,
    });
};

export const maximizeScanner = () => {
    postMessage({
        action: WebViewMessageType.MAXIMIZE_SCANNER,
        payload: undefined,
    });
};

export const hidePaymentModal = () => {
    postMessage({
        action: WebViewMessageType.HIDE_PAYMENT_MODAL,
        payload: undefined,
    });
};

export const requestBiometricsLogin = () => {
    postMessage({
        action: WebViewMessageType.BIOMETRICS_LOGIN,
        payload: undefined,
    });
};

export const setupBiometrics = () => {
    postMessage({
        action: WebViewMessageType.SETUP_BIOMETRICS,
        payload: undefined,
    });
};

export const deleteBiometrics = () => {
    postMessage({
        action: WebViewMessageType.DELETE_BIOMETRICS,
        payload: undefined,
    });
};

export const googleSignIn = () => {
    postMessage({
        action: WebViewMessageType.GOOGLE_SIGNIN,
        payload: undefined,
    });
};

export const appleSignIn = () => {
    postMessage({
        action: WebViewMessageType.APPLE_SIGNIN,
        payload: undefined,
    });
};

export const platformPayCheckout = () => {
    postMessage({
        action: WebViewMessageType.PLATFORM_PAY,
        payload: { method: Guest.PLATFORM_PAY },
    });
};

export const creditCardCheckout = () => {
    postMessage({
        action: WebViewMessageType.CREDIT_CARD,
        payload: { method: Guest.CREDIT_CARD },
    });
};

export const logToNative = (value: string) => {
    postMessage({
        action: WebViewMessageType.LOG,
        payload: value,
    });
};

export const openAppStore = () => {
    postMessage({
        action: WebViewMessageType.OPEN_APP_STORE,
        payload: undefined,
    });
};

export const notifyLowSignalScan = (val: boolean) => {
    postMessage({
        action: WebViewMessageType.SCAN_LOW_SIGNAL,
        payload: val,
    });
};

export const notifyLowSignalQueue = (val: boolean) => {
    postMessage({
        action: WebViewMessageType.QUEUE_LOW_SIGNAL,
        payload: val,
    });
};

export const requestLocation = () => {
    postMessage({
        action: WebViewMessageType.REQUEST_LOCATION,
        payload: undefined,
    });
};

export const queueRemote = (chargieId: string, remoteType: string) => {
    postMessage({
        action: WebViewMessageType.QUEUE_REMOTE,
        payload: {
            chargieId: chargieId,
            remoteType: remoteType,
        },
    });
};

// Sets the color of the iOS time & wifi icon
export const setStatusBarLight = (val: boolean) => {
    postMessage({
        action: WebViewMessageType.SET_STATUS_BAR_LIGHT,
        payload: val,
    });
};

export const openAppSettings = () => {
    postMessage({
        action: WebViewMessageType.OPEN_APP_SETTINGS,
        payload: undefined,
    });
};

export const getSignupSubscription = () => {
    postMessage({
        action: WebViewMessageType.GET_SIGNUP_SUBSCRIPTION,
        payload: undefined,
    });
};

export const setSignupSubscription = (val: boolean) => {
    postMessage({
        action: WebViewMessageType.SET_SIGNUP_SUBSCRIPTION,
        payload: val,
    });
};

export const hardResetApp = () => {
    postMessage({
        action: WebViewMessageType.HARD_RESET_APP,
        payload: undefined,
    });
};

export const cancelQueuedStart = () => {
    postMessage({
        action: WebViewMessageType.CANCEL_REMOTE_START,
        payload: undefined,
    });
};

export const dismissLowSignalModal = () => {
    postMessage({
        action: WebViewMessageType.DISMISS_LOW_SIGNAL_MODAL,
        payload: undefined,
    });
};

export const open3dsModal = (link: string | undefined) => {
    postMessage({
        action: WebViewMessageType.OPEN_3DS_MODAL,
        payload: link,
    });
};

export const sendRetryAddFunds = (value: boolean | null | undefined) => {
    postMessage({
        action: WebViewMessageType.RETRY_ADD_FUNDS,
        payload: value,
    });
};

export const getReviewPrompt = () => {
    postMessage({
        action: WebViewMessageType.GET_REVIEW_PROMPT,
        payload: undefined,
    });
};

export const triggerReviewPrompt = () => {
    postMessage({
        action: WebViewMessageType.TRIGGER_REVIEW,
        payload: undefined,
    });
};

export const triggerRefresh = () => {
    postMessage({
        action: WebViewMessageType.REFRESH_PAGE,
        payload: undefined,
    });
};
