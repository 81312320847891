/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery, useQuery } from '@apollo/client';
import { differenceInMinutes, parseISO, set } from 'date-fns';
import { loader } from 'graphql.macro';
import React, {
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { store } from '../app/store';
import {
    selectActiveChargieId,
    selectChargeDuration,
    selectChargeStartTime,
    selectChargeStatus,
    selectInactiveChargieId,
    selectPenaltyStation,
    setActiveChargieId,
    setChargeAmp,
    setChargeCost,
    setChargeDuration,
    setChargeKw,
    setChargeKwh,
    setChargeStartTime,
    setChargeStatus,
    setErrorType,
    setFinishedTransaction,
    setInactiveChargieId,
    setPenaltyStation,
    setRequestedChargieId,
} from '../appSlice';
import {
    ChargeStatus,
    RemoteErrorTypes,
    SecureStorageKeys,
} from '../utils/enums';
import { roundDown } from '../utils/utility';
import { logToNative, saveData } from '../utils/webview/messages';

interface WebSocketContextType {
    messages: string[];
}
const WebSocketContext = createContext<WebSocketContextType | undefined>(
    undefined
);

interface WebSocketProviderProps {
    children: ReactNode;
}
const getCurrentTransactionQuery = loader(
    '../gql/getCurrentTransaction.graphql'
);
const getStationPricing = loader('../gql/getStationPricing.graphql');
const getRecentTransactionsForUserQuery = loader(
    '../containers/ChargingHistoryPage/getRecentTransactionsForUser.graphql'
);

const WebSocketProvider: React.FC<WebSocketProviderProps> = ({ children }) => {
    const dispatch = useAppDispatch();
    const [messages, setMessages] = useState<string[]>([]);
    const socketRef = useRef<WebSocket | null>(null);

    let connectTimeout: NodeJS.Timeout | null;
    let idleSocketTimeout: NodeJS.Timeout | null;
    // Have to keep this app selector for the useEffect
    const activeChargieId = useAppSelector(selectActiveChargieId);
    const inactiveChargieId = useAppSelector(selectInactiveChargieId);
    const chargeStatus = useAppSelector(selectChargeStatus);
    const chargeStartTime = useAppSelector(selectChargeStartTime);
    const isPenalty = useAppSelector(selectPenaltyStation);
    // const [isPenalty, setIsPenalty] = useState<boolean | undefined>(undefined);
    let penalty = false;
    const navigate = useNavigate();
    const location = useLocation();
    const [timer, setTimer] = useState<number | null>(null);
    const [pollTimeout, setPollTimeout] = useState<number | null>(null);
    const chargeDuration = useAppSelector(selectChargeDuration);
    const [getCurrentTransaction, { data: currentTransactionData }] =
        useLazyQuery(getCurrentTransactionQuery, {
            fetchPolicy: 'no-cache',
        });

    const [getRecentTransactionsForUser, { data: recentTransactionsData }] =
        useLazyQuery(getRecentTransactionsForUserQuery, {
            fetchPolicy: 'no-cache',
        });

    const [getStationDetails, { data: getStationPricingdata }] = useLazyQuery(
        getCurrentTransactionQuery,
        {
            fetchPolicy: 'no-cache',
        }
    );

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (
                document.visibilityState === 'visible' &&
                store.getState().app.activeChargieId
            ) {
                getCurrentTransaction();
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        // Clean up the event listener on component unmount
        return () => {
            document.removeEventListener(
                'visibilitychange',
                handleVisibilityChange
            );
        };
    }, []);

    useEffect(() => {
        if (
            !chargeStartTime ||
            chargeStatus === ChargeStatus.FINISHED ||
            chargeStatus === ChargeStatus.PREPARING ||
            timer
        ) {
            return;
        }

        const interval = window.setInterval(() => {
            const now = new Date();
            const current = Math.floor(
                (now.getTime() - chargeStartTime) / 1000
            );
            dispatch(setChargeDuration(current));
        }, 1000);
        setTimer(interval);
    }, [chargeStartTime]);
    useEffect(() => {
        if (chargeDuration === null && timer) {
            clearInterval(timer);
        }
    }, [chargeDuration]);

    const updateStatus = (status: string) => {
        const newStatus = status.toUpperCase();
        if (
            chargeStartTime === null &&
            (newStatus === ChargeStatus.SUSPENDEDEV ||
                newStatus === ChargeStatus.CHARGING ||
                newStatus === ChargeStatus.SUSPENDEDEVSE ||
                newStatus === ChargeStatus.IDLE)
        ) {
            const now = new Date();
            dispatch(setChargeStartTime(now.getTime()));
        }

        if (newStatus === ChargeStatus.PENDING) {
            dispatch(setChargeStatus(ChargeStatus.PREPARING));
            dispatch(setChargeDuration(null));
            dispatch(setChargeStartTime(null));
        } else if (
            newStatus === ChargeStatus.SUSPENDEDEV ||
            newStatus === ChargeStatus.SUSPENDEDEVSE
        ) {
            dispatch(setChargeStatus(ChargeStatus.IDLE));
        } else if (status === ChargeStatus.COMPLETE && isPenalty) {
            dispatch(setChargeStatus(ChargeStatus.FINISHING));
        } else {
            const currentStatus = store.getState().app
                .chargeStatus as ChargeStatus;
            if (
                (currentStatus === ChargeStatus.PENDING ||
                    currentStatus === ChargeStatus.PREPARING) &&
                (newStatus === ChargeStatus.AVAILABLE ||
                    newStatus === ChargeStatus.ABORTED)
            ) {
                dispatch(setErrorType(RemoteErrorTypes.EV_NOT_DETECTED));
                if (location.pathname === '/session') {
                    navigate('/', { replace: true });
                }
            }
            dispatch(setChargeStatus(newStatus));
        }
    };

    useEffect(() => {
        if (getStationPricingdata) {
            try {
                const item = JSON.parse(
                    getStationPricingdata.loggedInUser.currentTransaction
                        .transactionCache
                );
                penalty = item.isPenalty;
                dispatch(setPenaltyStation(item.isPenalty));
                const startTime = new Date(item.chargeStartTime);
                const currentStatus = item.chargeStatus.toUpperCase();
                if (
                    currentStatus === ChargeStatus.ABORTED ||
                    currentStatus === ChargeStatus.FINALIZED ||
                    (currentStatus === ChargeStatus.COMPLETE &&
                        !item.isPenalty &&
                        (item.penalty !== 0 || item.penalty.delayMinutes !== 0))
                ) {
                    resetChargeStatus();
                    return;
                } else if (
                    currentStatus === ChargeStatus.COMPLETE &&
                    item.isPenalty
                ) {
                    dispatch(setChargeStatus(ChargeStatus.FINISHING));
                }
                if (
                    !chargeStartTime &&
                    (currentStatus === ChargeStatus.CHARGING ||
                        currentStatus === ChargeStatus.SUSPENDEDEV ||
                        currentStatus === ChargeStatus.SUSPENDEDEVSE ||
                        currentStatus === ChargeStatus.IDLE)
                ) {
                    dispatch(setChargeStartTime(startTime.getTime()));
                }
                dispatch(
                    setChargeAmp(
                        item.ampHistoryWithStatus.length > 0 &&
                            item.ampHistoryWithStatus[0].length > 0
                            ? Math.floor(item.ampHistoryWithStatus[0][0])
                            : 0
                    )
                );
                const totalWh =
                    item.lastKnownWh - (item.startingWh || item.firstKnownWh) ||
                    0;
                dispatch(setChargeStatus(item.chargeStatus.toUpperCase()));
                dispatch(setChargeKwh(roundDown(totalWh / 1000)));
                dispatch(setChargeKw(roundDown((item.liveWatts || 0) / 1000)));
                dispatch(setChargeCost(item.totalCost || 0));
            } catch (e) {
                console.log(e);
            }
        } else if (!activeChargieId || activeChargieId == '') {
            resetChargeStatus();
        }
    }, [getStationPricingdata]);

    // let pollTimeout: NodeJS.Timer | undefined;
    let pollTimeoutCheck = true;

    useEffect(() => {
        if (
            (chargeStatus === ChargeStatus.SUSPENDEDEV ||
                chargeStatus === ChargeStatus.SUSPENDEDEVSE ||
                chargeStatus === ChargeStatus.CHARGING ||
                chargeStatus === ChargeStatus.IDLE) &&
            !chargeStartTime
        ) {
            if (pollTimeout) clearTimeout(pollTimeout);
            const now = new Date();
            dispatch(setChargeStartTime(now.getTime()));
        } else if (
            chargeStatus === ChargeStatus.QUEUED ||
            chargeStatus === ChargeStatus.PENDING ||
            chargeStatus === ChargeStatus.PREPARING ||
            chargeStatus === ChargeStatus.LOADING ||
            chargeStatus === ChargeStatus.COMPLETE
        ) {
            if (activeChargieId && !pollTimeout && pollTimeoutCheck) {
                pollTimeoutCheck = false;
                setTimeout(() => {
                    const pollTimeoutInterval = window.setInterval(
                        async () => await getCurrentTransaction(),
                        10000
                    );
                    setPollTimeout(pollTimeoutInterval);
                }, 2000);
            } else {
                if (!(chargeStatus === ChargeStatus.COMPLETE && isPenalty)) {
                    dispatch(setChargeDuration(null));
                    dispatch(setChargeStartTime(null));
                }
            }
        } else {
            if (pollTimeout) clearTimeout(pollTimeout);
        }
        if (
            chargeStatus === ChargeStatus.SUSPENDEDEV ||
            chargeStatus === ChargeStatus.SUSPENDEDEVSE
        )
            dispatch(setChargeStatus(ChargeStatus.IDLE));
        else if (chargeStatus === ChargeStatus.PENDING)
            dispatch(setChargeStatus(ChargeStatus.PREPARING));
        if (
            chargeStatus === ChargeStatus.ABORTED ||
            chargeStatus === ChargeStatus.FINALIZED
        ) {
            resetChargeStatus();
        } else if (
            (chargeStatus === ChargeStatus.COMPLETE ||
                chargeStatus === ChargeStatus.FINISHING) &&
            isPenalty === false &&
            !inactiveChargieId
        ) {
            if (
                store.getState().app.activeChargieId &&
                store.getState().app.activeChargieId !== ''
            ) {
                dispatch(
                    setInactiveChargieId(store.getState().app.activeChargieId)
                );
            }
            setTimeout(async () => {
                await getRecentTransactionsForUser();
                resetChargeStatus();
                return;
            }, 5000);
        } else if (chargeStatus === ChargeStatus.COMPLETE && isPenalty) {
            dispatch(setChargeStatus(ChargeStatus.FINISHING));
        }
    }, [chargeStatus, isPenalty]);

    useEffect(() => {
        const performActions = async () => {
            if (
                chargeStatus === ChargeStatus.CHARGING ||
                chargeStatus === ChargeStatus.IDLE ||
                chargeStatus === ChargeStatus.FINISHED ||
                chargeStatus === ChargeStatus.COMPLETE
            ) {
                if (pollTimeout) clearTimeout(pollTimeout);
            }
            try {
                if (
                    currentTransactionData.loggedInUser?.currentTransaction
                        ?.transactionCache &&
                    currentTransactionData.loggedInUser?.currentTransaction
                        ?.transactionCacheDetails
                ) {
                    const parsedCache = JSON.parse(
                        currentTransactionData.loggedInUser.currentTransaction
                            .transactionCache
                    );
                    const {
                        status,
                        cost,
                        liveAmps,
                        liveWatts,
                        wattHours,
                        pricingPolicy,
                    } =
                        currentTransactionData.loggedInUser?.currentTransaction
                            ?.transactionCacheDetails;
                    penalty = parsedCache.isPenalty;
                    dispatch(setPenaltyStation(parsedCache.isPenalty));
                    const chargeStart = new Date(parsedCache.chargeStartTime);
                    if (!chargeStartTime) {
                        store.dispatch(
                            setChargeStartTime(chargeStart.getTime())
                        );
                    }

                    dispatch(setChargeKwh(roundDown(wattHours)));
                    if (
                        socketRef &&
                        socketRef.current?.readyState === WebSocket.CLOSED
                    ) {
                        await connect();
                    }
                    if (
                        status === ChargeStatus.COMPLETE &&
                        parsedCache.isPenalty
                    ) {
                        if (chargeStatus !== ChargeStatus.FINISHING) {
                            dispatch(setChargeStatus(ChargeStatus.FINISHING));
                        }
                        const now = new Date();
                        const diffInMs = now.getTime() - chargeStart.getTime(); // Difference in milliseconds
                        const diffInMinutes = Math.floor(
                            diffInMs / (1000 * 60)
                        );
                        dispatch(setChargeAmp(0));
                        dispatch(setChargeKw(0));
                        if (
                            diffInMinutes >= pricingPolicy.penaltyDelay &&
                            diffInMinutes > 15
                        ) {
                            const penaltyHours = Math.ceil(
                                (diffInMinutes - 15) / 60
                            );
                            if (penaltyHours > 0) {
                                dispatch(
                                    setChargeCost(
                                        cost +
                                            penaltyHours *
                                                pricingPolicy.penaltyRate
                                    )
                                );
                            }
                        }
                    } else {
                        dispatch(setChargeStatus(status.toUpperCase()));
                        dispatch(setChargeCost(cost));
                        dispatch(setChargeAmp(roundDown(liveAmps)));
                        dispatch(setChargeKw(Math.floor(liveWatts / 10) / 100));
                    }
                } else {
                    if (isPenalty && activeChargieId && !inactiveChargieId) {
                        setTimeout(async () => {
                            await getRecentTransactionsForUser();
                            dispatch(setInactiveChargieId(activeChargieId));
                            resetChargeStatus();
                        }, 2000);
                    } else {
                        resetChargeStatus();
                    }
                }
            } catch {}
        };
        performActions();
    }, [currentTransactionData]);

    const resetChargeStatus = () => {
        saveData(SecureStorageKeys.ACTIVE_CHARGIE_ID, '');
        dispatch(setChargeDuration(null));
        dispatch(setChargeStartTime(null));
        dispatch(setChargeAmp(0));
        dispatch(setChargeKwh(0));
        dispatch(setChargeKw(0));
        dispatch(setChargeCost(0));
        dispatch(setChargeStatus(ChargeStatus.LOADING));
        dispatch(setActiveChargieId(null));
        dispatch(setRequestedChargieId(undefined));
        dispatch(setPenaltyStation(undefined));
        if (timer) {
            clearInterval(timer);
            setTimer(null);
        }
        if (pollTimeout) {
            clearInterval(pollTimeout);
            setPollTimeout(null);
        }
        if (location.pathname === '/session') navigate('/', { replace: true });
    };

    const socketOnOpen = () => {
        const date = new Date();
        console.log('WebSocket connected!', date.toISOString());
        if (connectTimeout) {
            clearTimeout(connectTimeout);
            connectTimeout = null;
        }
        if (idleSocketTimeout) {
            clearTimeout(idleSocketTimeout);
        }
        idleSocketTimeout = setTimeout(() => {
            socketRef.current?.close(3001, 'on open idle close');
        }, 60000);
        // Refetch the current transaction details
        setTimeout(() => {
            getCurrentTransaction();
        }, 10000);
    };

    const socketOnMessage = (event: MessageEvent<any>) => {
        const message = event.data as string;
        logToNative('WebSocket message! ' + message);
        const item = JSON.parse(message);
        if (item.status) {
            updateStatus(item.status);
        } else if (
            chargeStatus === ChargeStatus.COMPLETE ||
            chargeStatus === ChargeStatus.FINISHING ||
            (chargeStatus === ChargeStatus.FINISHED &&
                item.isComplete &&
                item.event === 'ChangeCompletion' &&
                isPenalty)
        ) {
            setTimeout(() => {
                getRecentTransactionsForUser();
                dispatch(
                    setInactiveChargieId(store.getState().app.activeChargieId)
                );
                resetChargeStatus();
                return;
            }, 2000);
        } else {
            dispatch(setChargeAmp(Math.floor(item.amps)));
            dispatch(setChargeKwh(roundDown(item.kwh || 0)));
            dispatch(setChargeKw(roundDown(item.liveKw || 0)));
            dispatch(setChargeCost(item.cost));
        }
        if (idleSocketTimeout) {
            clearTimeout(idleSocketTimeout);
        }
        idleSocketTimeout = setTimeout(() => {
            socketRef.current?.close(3001, 'on message idle close');
        }, 60000);
    };

    const socketOnClose = (e: CloseEvent) => {
        console.log('WebSocket closed', e);
        logToNative('Websocket closed');
        if (
            socketRef &&
            socketRef.current &&
            socketRef.current.readyState === WebSocket.OPEN
        )
            return;
        if (e.code !== 3000 && e.code !== 1000) {
            // Attempt reconnecting every 5 seconds if not already retrying
            if (!connectTimeout) {
                connectTimeout = setTimeout(() => connect(), 2000);
            }
        }
        if (idleSocketTimeout) {
            clearTimeout(idleSocketTimeout);
            idleSocketTimeout = null;
        }
        return;
    };

    const socketOnError = (e: Event) => {
        console.error('WebSocket error', e);
        socketRef.current?.close(3001, 'Websocket error');
    };

    const setupTimeout = () => {
        connectTimeout = setTimeout(() => {
            if (socketRef.current?.readyState !== WebSocket.OPEN) {
                console.error('WebSocket connection timed out');
                socketRef.current?.close(
                    3001,
                    'Websocket connection timed out'
                ); // This will trigger the onclose handler
            }
        }, 1000); // 1 seconds timeout for connection
    };

    const connect = async () => {
        if (
            socketRef &&
            socketRef.current &&
            socketRef.current.readyState === WebSocket.OPEN
        )
            return;
        if (store.getState().app.activeChargieId) {
            const wss = `${process.env.REACT_APP_WEBSOCKET_ENDPOINT}/v2/station/${store.getState().app.activeChargieId}/progress/socket`;
            const newSocket = new WebSocket(wss);
            newSocket.onopen = socketOnOpen;
            newSocket.onmessage = socketOnMessage;
            newSocket.onclose = socketOnClose;
            newSocket.onerror = socketOnError;

            // Close the socket after a 1 second timeout
            // Closing the socket will trigger a socket reinitialization
            setupTimeout();
            socketRef.current = newSocket;
        }
    };

    useEffect(() => {
        if (activeChargieId && activeChargieId !== '') {
            connect();
            getStationDetails({
                variables: { qrCode: store.getState().app.activeChargieId },
            });
        } else {
            socketRef.current?.close(3000, 'no more active chargie id close');
        }
    }, [activeChargieId]);

    useEffect(() => {
        if (
            inactiveChargieId &&
            recentTransactionsData?.recentTransactionsForUser
        ) {
            const lastTransactionForStation =
                recentTransactionsData.recentTransactionsForUser.find(
                    (t: any) => t.station?.qrCode === inactiveChargieId
                );
            if (
                lastTransactionForStation &&
                lastTransactionForStation.startedAt &&
                lastTransactionForStation.stoppedAt
            ) {
                const dt1 = parseISO(lastTransactionForStation.startedAt);
                const dt2 = parseISO(lastTransactionForStation.stoppedAt);
                const duration = differenceInMinutes(dt2, dt1);
                dispatch(
                    setFinishedTransaction({
                        kwh: lastTransactionForStation.wh / 1000,
                        duration,
                        cost: lastTransactionForStation.amount,
                    })
                );
            }
        }
    }, [inactiveChargieId, recentTransactionsData]);

    // Close the socket on unmount
    useEffect(() => {
        return () => socketRef.current?.close(3000, 'unmount close');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <WebSocketContext.Provider value={{ messages }}>
            {children}
        </WebSocketContext.Provider>
    );
};

const useWebSocket = (): WebSocketContextType => {
    const context = useContext(WebSocketContext);
    if (context === undefined) {
        throw new Error('useWebSocket must be used within a WebSocketProvider');
    }
    return context;
};

export { WebSocketProvider, useWebSocket };
